
/* Box-sizing. */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  /* Variables */
  --color--black: #000;
  --color--dark-grey: #2F343F;
  --color--grey: #949494;
  --color--medium-grey: #D5D5D5;
  --color--light-grey: #F3F3F3;
  --color--white: #FFF;
  --color--green: #86BD01;
  --color--green-a11y: #6B9801;
  --color--blue: #324FBD;

  /* Color-schem preferences */
  background-color: var(--color--white);
  color: var(--color--black);

  @media (prefers-color-scheme: dark) {
    filter: invert(0.9) hue-rotate(180deg);
  }

  /* Typography */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Layout */
body {
  margin: 0;
}

.layout-container {
  width: calc(100% - 20vw);
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.page--header {
  padding: 1.5rem 0;
  border-bottom: 0.0625rem solid var(--color--medium-grey);
}

.page--main {
  padding: 5rem 0;
}

textarea {
  display: block;
  width: 100%;
  padding: 1rem;
}

h1 {
  font-size: 2.6rem;
  line-height: 1.1;
  margin: 0 0 0.5em 0;
}

a {
  color: currentColor;
  font-weight: 500;
  text-underline-offset: 0.5em;
  text-decoration-color: var(--color--green-a11y);

  &:hover {
    text-decoration-thickness: 0.1em;
  }
}

form {
  > * {
    + * {
      margin-top: 3rem;
    }
  }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  .result {
    .actions {
      margin-top: 1rem;
    }
  }

  .actions {
    display: flex;
    gap: 1rem;
  }
}

button {
  border-radius: 0.5rem;
  border: none;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.25s ease;

  &:not([disabled]) {
    background-color: var(--color--blue);
    color: var(--color--white);

    &:hover {
      background-color: var(--color--black);
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}
