html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  --color--black: #000;
  --color--dark-grey: #2f343f;
  --color--grey: #949494;
  --color--medium-grey: #d5d5d5;
  --color--light-grey: #f3f3f3;
  --color--white: #fff;
  --color--green: #86bd01;
  --color--green-a11y: #6b9801;
  --color--blue: #324fbd;
  background-color: var(--color--white);
  color: var(--color--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  @media (prefers-color-scheme: dark) {
    & {
      filter: invert(.9) hue-rotate(180deg);
    }
  }
}

body {
  margin: 0;
}

.layout-container {
  width: calc(100% - 20vw);
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.page--header {
  border-bottom: .0625rem solid var(--color--medium-grey);
  padding: 1.5rem 0;
}

.page--main {
  padding: 5rem 0;
}

textarea {
  width: 100%;
  padding: 1rem;
  display: block;
}

h1 {
  margin: 0 0 .5em;
  font-size: 2.6rem;
  line-height: 1.1;
}

a {
  color: currentColor;
  text-underline-offset: .5em;
  -webkit-text-decoration-color: var(--color--green-a11y);
  text-decoration-color: var(--color--green-a11y);
  font-weight: 500;

  &:hover {
    text-decoration-thickness: .1em;
  }
}

form {
  & > * {
    & + * {
      margin-top: 3rem;
    }
  }

  & label {
    margin-bottom: .5em;
    font-weight: bold;
    display: block;
  }

  & .result {
    & .actions {
      margin-top: 1rem;
    }
  }

  & .actions {
    gap: 1rem;
    display: flex;
  }
}

button {
  cursor: pointer;
  border: none;
  border-radius: .5rem;
  padding: .6em 1.2em;
  font-family: inherit;
  font-size: 1em;
  font-weight: 500;
  transition: all .25s;

  &:not([disabled]) {
    background-color: var(--color--blue);
    color: var(--color--white);

    &:hover {
      background-color: var(--color--black);
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}
/*# sourceMappingURL=index.fe1c7526.css.map */
